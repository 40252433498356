.swiper-custom {
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100%;
}

.swiper-slide {
    background: transparent;
}

.swiper-other-services-button {
    position: absolute;
    top: 50%;

    /*background: #eff7fa;*/
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    /*z-index: 10;*/
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 0;
    height: 0;
    border-style: solid;
}

.swiper-other-services-button-next {
    right: -50px;
    border-width: 30px 0 30px 30px;
    border-color: transparent transparent transparent #eff7fa;
}

.swiper-other-services-button-prev {
    left: -50px;
    border-width: 30px 30px 30px 0;
    border-color: transparent #eff7fa transparent transparent;
}

.landing-page-wrapper .swiper-pagination-bullets,
.landing-page-wrapper .swiper-pagination-custom,
.landing-page-wrapper .swiper-pagination-fraction {
    right: auto !important;
    left: 70px !important;
}

.swiper-bullet-custom {
    display: inline-block;
    padding: 12px;
    margin: 10px 0 !important;
    position: relative;
    background: transparent !important;
    opacity: 1 !important;
}

.swiper-bullet-custom::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background: #083e71;
    transition: all 0.3s ease-in;
}

.swiper-bullet-custom.swiper-pagination-bullet-active::after {
    background: #6c7f96;
}

.swiper-pagination-services {
    position: absolute;
    width: 100%;
    bottom: 21% !important;
}




.landing-page-section-info {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);

    width: 30%;
    color: #083e71;
    border-bottom: 1px solid #083e71;
    /*padding-bottom: 10px;*/
    text-align: center;

    font-family: 'Hind', serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 5px;
}

.landing-page-wrapper__static-background {
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    transition: all 1s ease-in;
    opacity: 0;

    background-image: url(resources/images/Drugi_slajd_radca_prawny.jpg);
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
}

.landing-page-wrapper__static-background--active {
    opacity: 1;
}

.landing-page-hello {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page-hello::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(resources/images/Pierwszy_slajd.jpg);
    background-size: cover;
    background-position: center;

    transition: all 1s ease-in;
    opacity: 0;
}

.landing-page-wrapper {
    position: relative;
}

.landing-page-hello.swiper-slide-active::before {
    opacity: 1;
}

.landing-page-hello__logo {
    width: 600px;
}

.landing-page-services__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    transition: all 0.5s ease-in;
    transform: scale(1.05);
}

.landing-page-services__image--active {
    opacity: 1;
    transform: scale(1);
}

.landing-page-services__image--1 {
    background-image: url('resources/images/BOK_udzielanie_porad_prawnych.jpg');
}

.landing-page-services__image--2 {
    background-image: url('resources/images/BOK_sporzadzanie_opinii_prawnych.jpg');
}

.landing-page-services__image--3 {
    background-image: url('resources/images/BOK_tworzenie_opiniowanie_umow.jpg');
}

.landing-page-services__image--4 {
    background-image: url('resources/images/BOK_zastepstwo_procesowe.jpg');
}

.landing-page-services__menu-item p {
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.landing-page-services__menu-item--active p,
.landing-page-services__menu-item p:hover {
    color: #083e71;
}

.landing-page-specs__texts {
    position: relative;
}

/*.landing-page-contact__address {*/
/*    margin-top: 10px;*/
/*}*/
