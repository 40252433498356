body {
    margin: 0;
    padding: 0;
    font-family: 'Playfair Display', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}

p {
    padding: 0;
    margin: 0;
}

a {
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: #083e71;
    transition: color 0.3s ease-in;
}

a:hover,
a:focus {
    color: #6c7f96;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;

    transition: opacity 1s ease-out;
}
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;

    transition: opacity 1s ease-out;
}
